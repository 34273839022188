/*** REACT ***/
import React from "react";
import { Link } from "gatsby";

/*** COMPONENTS ***/
import Main from "../components/main";
import SEO from "../components/seo";
import "../styles/index.css";

/*** CONSTANTS ***/
import * as ROUTES from "../constants/routes";
import previewWKIT from "../images/wkitPreview.png";
import previewMTD from "../images/mtd.png";
import previewMette from "../images/mette.png";
import PreviewEricsson from "../gatsby_images/image-previewericsson";

/* EXTRACURRICULAR WORK */
const Work = () => (
  <Main>
    <SEO title="Work" />
    <div class="projects-cards-container">
      <div class="projects-card" style={{ "--animation-order": 3 }}>
        <img alt="Preview-WKIT" src={previewWKIT} />
        <Link className="projects-link-style" to={ROUTES.WKIT} />
        <div class="projects-circle">
          <p class="projects-header-text-black">WE KNOW IT</p>
          <p class="projects-header-text-grey">WEB DEVELOPMENT</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 1 }}>
        <PreviewEricsson />
        <Link class="projects-link-style" to={ROUTES.ERICSSON} />
        <div class="projects-circle">
          <p class="projects-header-text-black">ERICSSON</p>
          <p class="projects-header-text-grey">JAVA DEVELOPER</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 4 }}>
        <img alt="Preview-MTD" src={previewMTD} />
        <Link class="projects-link-style" to={ROUTES.MTD} />
        <div class="projects-circle">
          <p class="projects-header-text-black">MTD</p>
          <p class="projects-header-text-grey">TREASURER</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 2 }}>
        <img alt="Preview-Mette" src={previewMette} />
        <Link class="projects-link-style" to={ROUTES.METTE} />
        <div class="projects-circle">
          <p class="projects-header-text-black">METTE</p>
          <p class="projects-header-text-grey">SECRETARY</p>
        </div>
      </div>
    </div>
  </Main>
);

export default Work;
